import { useRouteError, isRouteErrorResponse, Link } from "@remix-run/react";
import { AlertCircle } from "lucide-react";
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from "@kubrick/ui/components/ui/alert";
import { Button } from "@kubrick/ui/components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@kubrick/ui/components/ui/card";
import { captureRemixErrorBoundaryError } from "@sentry/remix";

export const ErrorBoundaryScreen = () => {
  const error = useRouteError();

  captureRemixErrorBoundaryError(error);

  let errorMessage = "An unexpected error occurred.";
  let errorDetails = "";

  if (isRouteErrorResponse(error)) {
    errorMessage = error.data.message || `${error.status} ${error.statusText}`;
    errorDetails = error.data.stack;
  } else if (error instanceof Error) {
    errorMessage = error.message;
    errorDetails = error.stack || "";
  } else if (error && typeof error === "object" && "message" in error) {
    // Handle cases where error is an object with a message property
    errorMessage = String(error.message);
    errorDetails = JSON.stringify(error, null, 2);
  } else if (error !== null && error !== undefined) {
    // Handle any other non-null, non-undefined errors
    errorMessage = "An unknown error occurred.";
    errorDetails = String(error);
  }

  return (
    <div className="flex min-h-screen items-center justify-center bg-background p-4">
      <Card className="w-full max-w-md">
        <CardHeader>
          <CardTitle className="flex items-center justify-center text-3xl font-bold">
            <AlertCircle className="mr-2 h-8 w-8 text-destructive" />
            Oops! Something went wrong
          </CardTitle>
        </CardHeader>
        <CardContent className="space-y-4">
          <Alert variant="destructive">
            <AlertCircle className="h-4 w-4" />
            <AlertTitle>Error</AlertTitle>
            <AlertDescription>{errorMessage}</AlertDescription>
          </Alert>
          <p className="text-center text-muted-foreground">
            We're already working on it
          </p>
          {errorDetails && (
            <Card>
              <CardHeader>
                <CardTitle className="text-lg">Error Details</CardTitle>
              </CardHeader>
              <CardContent>
                <pre className="max-h-[200px] overflow-auto whitespace-pre-wrap text-sm text-muted-foreground">
                  {errorDetails}
                </pre>
              </CardContent>
            </Card>
          )}
        </CardContent>
        <CardFooter className="flex justify-center">
          <Link to="/">
            <Button variant="default">Go back to homepage</Button>
          </Link>
        </CardFooter>
      </Card>
    </div>
  );
};
